import type { ReadonlyJSONValue } from "replicache"
import * as R from "remeda"

import type { IssueLinkExisting, IssuesGet, ThreadsGet } from "@productlane/api"

import type { MutationContext } from "../../types"
import { threadGet } from "../threads/getId"

export async function issueExistingLink({
	tx,
	args,
	workspaceId,
}: MutationContext<IssueLinkExisting>) {
	const key = `${workspaceId}/issues/${args.issueId}`
	const issue = (await tx.get(key)) as unknown as IssuesGet[number] | undefined
	if (!issue) {
		throw new Error("Issue not found")
	}

	const payload: IssuesGet[number] = {
		...issue,
		feedbackIds: R.uniq([...issue.feedbackIds, args.threadId]),
	}

	await tx.set(key, payload as unknown as ReadonlyJSONValue)

	const feedback = await threadGet({
		workspaceId,
		tx,
		args: { id: args.threadId },
	})
	if (!feedback) {
		throw new Error("Could not find feedback")
	}
	const newFeedback: ThreadsGet[number] = {
		...feedback,
		linkedIssueIds: R.uniq([...feedback.linkedIssueIds, args.issueId]),
	}
	await tx.set(
		`${workspaceId}/threads/${feedback.id}`,
		newFeedback as unknown as ReadonlyJSONValue,
	)
}
