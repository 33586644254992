import { useCallback, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import type { ThreadTab } from "../thread-context"
import type { Virtualizer } from "@tanstack/react-virtual"
import { useThreads, useWorkspace } from "@/lib/replicache"
import { useSession } from "@/lib/session"
import { byInboxThreads } from "@/lib/threads/filter"
import { useFilteredNotes } from "../../notes/filter/use-filtered-notes"

export function useInitThread() {
	const navigate = useNavigate()
	const params = useParams()
	const id = params.id
	const tab = params.tab as ThreadTab
	const inboxState = params.inboxState
	const threads = useThreads()
	const workspace = useWorkspace()
	const thread = useMemo(() => {
		return threads.find((t) => t.id === id)
	}, [id, threads])
	const { session } = useSession()

	const filteredThreads = useFilteredNotes(
		thread?.state === "PROCESSED"
			? "processed"
			: thread?.state === "SNOOZED"
				? "snoozed"
				: "inbox",
	)
	const nextThread = useMemo(() => {
		const sameStatusThreads =
			thread?.state === "PROCESSED"
				? filteredThreads.filter(({ state }) => state === "PROCESSED")
				: thread?.state === "SNOOZED"
					? filteredThreads.filter(({ state }) => state === "SNOOZED")
					: filteredThreads.filter(byInboxThreads)
		const sameStateThreads = sameStatusThreads.filter((i) => {
			if (inboxState === "all") {
				return true
			}
			if (inboxState === "my") {
				return i.assigneeId === session?.userId
			}
			if (inboxState === "unassigned") {
				return i.assigneeId === null
			}
		})
		const currIdx = sameStateThreads.findIndex((t) => t.id === thread?.id)
		const next = sameStateThreads[currIdx + 1] ?? null
		return next
	}, [thread?.state, thread?.id, filteredThreads, inboxState, session?.userId])
	const prevThread = useMemo(() => {
		const sameStatusThreads =
			thread?.state === "PROCESSED"
				? filteredThreads.filter(({ state }) => state === "PROCESSED")
				: thread?.state === "SNOOZED"
					? filteredThreads.filter(({ state }) => state === "SNOOZED")
					: filteredThreads.filter(byInboxThreads)
		const sameStateThreads = sameStatusThreads.filter((i) => {
			if (inboxState === "all") {
				return true
			}
			if (inboxState === "my") {
				return i.assigneeId === session?.userId
			}
			if (inboxState === "unassigned") {
				return i.assigneeId === null
			}
		})
		const currIdx = sameStateThreads.findIndex((t) => t.id === thread?.id)
		const next = sameStateThreads[currIdx - 1] ?? null
		return next
	}, [thread?.state, thread?.id, filteredThreads, inboxState, session?.userId])
	const goNext = useCallback(() => {
		if (!nextThread || !thread) {
			return
		}
		const currentPath = window.location.pathname
		const newPath = currentPath.replace(thread.id, nextThread.id)
		navigate(newPath, { replace: true })
	}, [navigate, nextThread, thread])
	const goPrev = useCallback(() => {
		if (!prevThread || !thread) {
			return
		}
		const currentPath = window.location.pathname
		const newPath = currentPath.replace(thread.id, prevThread.id)
		navigate(newPath, { replace: true })
	}, [navigate, prevThread, thread])
	const changeTab = useCallback(
		(tab: ThreadTab) => {
			navigate(`/threads/${inboxState ? inboxState : "all"}/${id}/${tab}`, {
				replace: true,
			})
		},
		[id, navigate, inboxState],
	)
	const [isReply, _setIsReply] = useState(false)
	const [isComment, _setIsComment] = useState(false)
	const setIsReply = useCallback(
		(isReply: boolean) => {
			if (
				!workspace?.emailSettings?.isEnabled &&
				!workspace?.slackConnectSettings?.length
			) {
				return
			}
			_setIsReply(isReply)
			if (isReply) {
				_setIsComment(false)
			}
		},
		[
			workspace?.emailSettings?.isEnabled,
			workspace?.slackConnectSettings?.length,
		],
	)
	const setIsComment = useCallback((isComment: boolean) => {
		_setIsComment(isComment)
		if (isComment) {
			_setIsReply(false)
		}
	}, [])
	const [transcriptVirtualizer, setTranscriptVirtualizer] = useState<{
		v: Virtualizer<HTMLDivElement, Element>
	} | null>(null)
	const [transcriptAccordionValue, setTranscriptAccordionValue] = useState<
		string | undefined
	>(undefined)
	return {
		thread,
		nextThread,
		prevThread,
		goNext,
		goPrev,
		changeTab,
		tab,
		isReply,
		setIsReply,
		isComment,
		setIsComment,
		transcriptVirtualizer,
		setTranscriptVirtualizer,
		transcriptAccordionValue,
		setTranscriptAccordionValue,
	}
}
