import { z } from "zod"

export const PaidPlanSchema = z.enum(["pro", "business", "enterprise"])
export type PaidPlan = z.infer<typeof PaidPlanSchema>
export type Plan = "free" | PaidPlan
export const PlanIntervalSchema = z.enum(["monthly", "yearly"])
export type PlanInterval = z.infer<typeof PlanIntervalSchema>
export const planHierarchy: Array<Plan> = [
	"free",
	"pro",
	"business",
	"enterprise",
]

export const free = {
	only: planHierarchy[0],
	andUp: planHierarchy.slice(0),
	andDown: planHierarchy.slice(0, 1),
}

export const FREE_PLAN_MEETING_LIMIT_TEXT =
	"The free plan includes ten recorded meetings or uploaded videos per month."
export const FREE_PLAN_LINK_LIMIT = 100
export const FREE_PLAN_LINK_LIMIT_TEXT =
	"The free plan includes one hundred total note links. A link represents a connection between a note and a project/issue/tag. Notes are always unlimited, note links are unlimited from the Pro plan and up."

export const pro = {
	only: planHierarchy[1],
	andUp: planHierarchy.slice(1),
	andDown: planHierarchy.slice(0, 2),
}

export const business = {
	only: planHierarchy[2],
	andUp: planHierarchy.slice(2),
	andDown: planHierarchy.slice(0, 3),
}

export const enterprise = {
	only: planHierarchy[3],
	andUp: planHierarchy.slice(3),
	andDown: planHierarchy.slice(0, 4),
}

export const RecordingLimits: Record<Plan, number> = {
	free: 10,
	pro: Infinity,
	business: Infinity,
	enterprise: Infinity,
} as const
