import type { LucideIcon } from "lucide-react"
import { useMemo } from "react"
import * as cheerio from "cheerio"
import { formatDistanceToNow, parseISO } from "date-fns"
import { Bolt, Code2Icon, FileText, Mail, Sun, Video } from "lucide-react"
import * as R from "remeda"

import type { ThreadsGet } from "@productlane/api"
import type { FeedbackOrigin } from "@productlane/db/browser"
import type { IconType } from "@productlane/ui"
import {
	FrontIcon,
	HubspotIcon,
	IntercomIcon,
	PlainLogo,
	SlackIcon,
	ZapierIcon,
	ZendeskIcon,
} from "@productlane/ui"

import { api } from "./api"

export const FeedbackOriginTextRecord: Record<FeedbackOrigin, string> = {
	API: "via API",
	INAPP: "in App",
	PORTAL: "via Portal",
	EMAIL: "via Email",
	SLACK: "via Slack",
	SLACK_CONNECT: "via Slack Connect",
	INTERCOM: "via Intercom",
	INTERCOM_ATTACHMENT: "via Intercom",
	FRONT_ATTACHMENT: "via Front",
	ZAPIER: "via Zapier",
	ZENDESK_ATTACHMENT: "via Zendesk",
	HUBSPOT: "via Hubspot",
	PLAIN: "via Plain",
	CALENDAR: "via Video",
	WIDGET: "via Widget",
}

export const FeedbackOriginIconRecord: Record<
	FeedbackOrigin,
	IconType | LucideIcon
> = {
	API: Code2Icon,
	INAPP: FileText,
	PORTAL: Sun,
	EMAIL: Mail,
	SLACK: SlackIcon,
	SLACK_CONNECT: SlackIcon,
	INTERCOM: IntercomIcon,
	INTERCOM_ATTACHMENT: IntercomIcon,
	FRONT_ATTACHMENT: FrontIcon,
	ZAPIER: ZapierIcon,
	ZENDESK_ATTACHMENT: ZendeskIcon,
	HUBSPOT: HubspotIcon,
	PLAIN: PlainLogo,
	CALENDAR: Video,
	WIDGET: Bolt,
}

export function getGrainHighlightIdsFromFeedbacks(args: {
	texts: Array<string>
	highlightId: string
}) {
	let grainIds: Array<string> = []
	for (const i of args.texts) {
		const $ = cheerio.load(i, { decodeEntities: false })

		const links = $(`mark[data-id=${args.highlightId}]`).parent("a")

		links.each((_, value) => {
			const href = $(value).attr("href")
			if (href?.includes("grain.com/share/highlight")) {
				const id = href.split("/").pop()
				if (id) {
					grainIds.push(id)
				}
			}
		})
	}
	grainIds = R.uniq(grainIds)
	return grainIds
}

export function useOriginText({ thread }: { thread?: ThreadsGet[number] }) {
	const teamMembers = api.workspace.members.useQuery(undefined, {
		cacheTime: Infinity,
		staleTime: Infinity,
	})

	const originText = useMemo(() => {
		if (thread) {
			const author = teamMembers.data?.find(
				(c) => c.userId === thread?.reporterId,
			)

			if (thread.origin === "INAPP") {
				return `Created ${formatDistanceToNow(
					parseISO(thread.createdAtIso),
				)} ago ${author ? `by ${author.user.name}` : ""}`
			}

			const text = FeedbackOriginTextRecord[thread.origin]

			return `Created ${formatDistanceToNow(
				parseISO(thread.createdAtIso),
			)} ago ${text}${author ? ` by ${author.user.name}` : ""}`
		}
		return ""
	}, [thread, teamMembers.data])

	return { originText, teamMembers }
}
