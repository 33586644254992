import type { ReadonlyJSONValue } from "replicache"

import type { IssuesGet, IssuesUpsert } from "@productlane/api"

import type { MutationContext } from "../../types"

export async function issuesUpsert({
	tx,
	workspaceId,
	args,
}: MutationContext<IssuesUpsert>) {
	const issues = (await tx
		.scan({ prefix: `${workspaceId}/issues/` })
		.values()
		.toArray()) as unknown as IssuesGet

	for (const linearIssue of args.linearIssues) {
		const existing = issues.find((i) => i.linearTicketId === linearIssue.id)
		if (existing) {
			const payload: IssuesGet[number] = {
				...existing,
				isVisible:
					typeof args.internalUpdate.isVisible !== "boolean"
						? existing.isVisible
						: args.internalUpdate.isVisible,
			}

			await tx.set(
				`${workspaceId}/issues/${existing.id}`,
				payload as unknown as ReadonlyJSONValue,
			)
		} else {
			const now = new Date()
			const data: IssuesGet[number] = {
				id: linearIssue.internalIssueId,
				createdAtIso: now.toISOString(),
				description: linearIssue.description ?? null,
				feedbackIds: [],
				identifier: linearIssue.identifier,
				isVisible:
					typeof args.internalUpdate.isVisible !== "boolean"
						? null
						: args.internalUpdate.isVisible,
				kluGuid: null,
				linearTicketId: linearIssue.id,
				priority: linearIssue.priorityLabel,
				projectId: "",
				publicDescription: null,
				sortOrder: 0,
				status: linearIssue.state.type,
				summary: [],
				summaryUpdatedAtIso: null,
				team: linearIssue.team.id,
				title: linearIssue.title,
				url: linearIssue.url,
				workspaceId,
			}
			await tx.set(
				`${workspaceId}/issues/${linearIssue.internalIssueId}`,
				data as unknown as ReadonlyJSONValue,
			)
		}
	}
}
