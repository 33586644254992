import React from "react"
import { compareDesc, parseISO } from "date-fns"
import { filter, pipe, sort } from "remeda"

import type { ThreadsGet } from "@productlane/api"
import type { PainLevel } from "@productlane/db"

import { useThreads } from "@/lib/replicache"

const PainLevelSorting: Record<PainLevel, number> = {
	HIGH: 0,
	MEDIUM: 1,
	LOW: 2,
	UNKNOWN: 3,
} as const

const byOldestResponses = (a: ThreadsGet[number], b: ThreadsGet[number]) => {
	const prev = {
		inbound: a.lastInboundMessageAtIso
			? parseISO(a.lastInboundMessageAtIso)
			: null,
		outbound: a.lastOutboundMessageAtIso
			? parseISO(a.lastOutboundMessageAtIso)
			: null,
		createdAt: parseISO(a.createdAtIso),
	}
	const next = {
		inbound: b.lastInboundMessageAtIso
			? parseISO(b.lastInboundMessageAtIso)
			: null,
		outbound: b.lastOutboundMessageAtIso
			? parseISO(b.lastOutboundMessageAtIso)
			: null,
		createdAt: parseISO(b.createdAtIso),
	}

	// Sorting null outgoing messages to the end
	if (prev.outbound && next.outbound === null) {
		return -1
	}

	// Replied threads sorting by oldest first
	if (prev.outbound && next.outbound) {
		if (prev.outbound < next.outbound) {
			return -1
		} else {
			return 0
		}
	}

	// Sorting null incoming messages to the end
	if (prev.inbound && next.inbound === null) {
		return -1
	}

	// Waiting for reply threads sorting by oldest first
	if (prev.inbound && next.inbound) {
		if (prev.inbound < next.inbound) {
			return -1
		} else {
			return 0
		}
	}

	return 0
}

export const useSnoozedThreads = () => {
	const threads = useThreads()
	return React.useMemo(
		() =>
			pipe(
				threads,
				filter(({ state }) => state === "SNOOZED"),
				sort((a, b) =>
					compareDesc(
						parseISO(a.lastStateChangeAtIso ?? a.createdAtIso),
						parseISO(b.lastStateChangeAtIso ?? b.createdAtIso),
					),
				),
			),
		[threads],
	)
}

export const useProcessedThreads = () => {
	const threads = useThreads()
	return React.useMemo(
		() =>
			pipe(
				threads,
				filter(({ state }) => state === "PROCESSED"),
				sort((a, b) =>
					compareDesc(
						parseISO(a.lastStateChangeAtIso ?? a.createdAtIso),
						parseISO(b.lastStateChangeAtIso ?? b.createdAtIso),
					),
				),
			),
		[threads],
	)
}

export const useInboxThreads = () => {
	const threads = useThreads()
	return React.useMemo(() => {
		const completedThreads: ThreadsGet = []
		return [
			...pipe(
				threads,
				filter((thread) => {
					if (thread.state === "PROCESSED" || thread.state === "SNOOZED") {
						return false
					}
					if (thread.state === "COMPLETED") {
						completedThreads.push(thread)
						return false
					}
					return true
				}),
				sort(byOldestResponses),
				sort(
					(a, b) =>
						PainLevelSorting[a.painLevel] - PainLevelSorting[b.painLevel],
				),
			),
			...completedThreads.sort(byOldestResponses),
		]
	}, [threads])
}
