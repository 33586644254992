import type { ReadonlyJSONValue } from "replicache"
import * as R from "remeda"

import type { IssueLink, IssuesGet, ThreadsGet } from "@productlane/api"

import type { MutationContext } from "../../types"
import { threadGet } from "../threads/getId"

export async function issueLink({
	tx,
	workspaceId,
	args,
}: MutationContext<IssueLink>) {
	const key = `${workspaceId}/issues/${args.internalIssueId}`
	const issues = (await tx
		.scan({ prefix: `${workspaceId}/issues/` })
		.values()
		.toArray()) as unknown as IssuesGet

	const existing = issues.find((i) => i.linearTicketId === args.linearIssue.id)
	if (existing) {
		const payload: IssuesGet[number] = {
			...existing,
			feedbackIds: R.uniq([...existing.feedbackIds, args.feedbackId]),
		}

		await tx.set(
			`${workspaceId}/issues/${existing.id}`,
			payload as unknown as ReadonlyJSONValue,
		)
		// just update the linked feedback
		const feedback = await threadGet({
			workspaceId,
			tx,
			args: { id: args.feedbackId },
		})
		if (!feedback) {
			throw new Error("Could not find feedback")
		}
		const newFeedback: ThreadsGet[number] = {
			...feedback,
			linkedIssueIds: R.uniq([...feedback.linkedIssueIds, existing.id]),
			linkedLinearIssueIds: R.uniq([
				...feedback.linkedLinearIssueIds,
				args.linearIssue.id,
			]),
		}
		await tx.set(
			`${workspaceId}/threads/${feedback.id}`,
			newFeedback as unknown as ReadonlyJSONValue,
		)
	} else {
		const now = new Date()
		const data: IssuesGet[number] = {
			id: args.internalIssueId,
			createdAtIso: now.toISOString(),
			description: args.linearIssue.description ?? null,
			feedbackIds: [args.feedbackId],
			identifier: args.linearIssue.identifier,
			isVisible: null,
			kluGuid: null,
			linearTicketId: args.linearIssue.id,
			priority: args.linearIssue.priorityLabel,
			projectId: "",
			publicDescription: null,
			sortOrder: 0,
			status: args.linearIssue.state.type,
			summary: [],
			summaryUpdatedAtIso: null,
			team: args.linearIssue.team.id,
			title: args.linearIssue.title,
			url: args.linearIssue.url,
			workspaceId,
		}
		await tx.set(key, data as unknown as ReadonlyJSONValue)
		const feedback = await threadGet({
			workspaceId,
			tx,
			args: { id: args.feedbackId },
		})
		if (!feedback) {
			throw new Error("Could not find feedback")
		}
		const newFeedback: ThreadsGet[number] = {
			...feedback,
			linkedIssueIds: R.uniq([
				...feedback.linkedIssueIds,
				args.internalIssueId,
			]),
			linkedLinearIssueIds: R.uniq([
				...feedback.linkedLinearIssueIds,
				args.linearIssue.id,
			]),
		}
		await tx.set(
			`${workspaceId}/threads/${feedback.id}`,
			newFeedback as unknown as ReadonlyJSONValue,
		)
	}
}
