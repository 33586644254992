import { createContext } from "react"

import type { Plan, PlanInterval } from "@productlane/lib/src/browser"

export type Prices = Record<Plan, Record<PlanInterval, string>>

export const PricingContext = createContext({
	subscriptionInterval: "monthly" as PlanInterval,
	prices: {} as Prices,
	isPublic: true,
})
