import type { WriteTransaction } from "replicache"

import type {
	ChangelogCreate,
	ChangelogDelete,
	ChangelogUpdate,
	CommentCreate,
	CommentDelete,
	CommentUpdate,
	CompanyCreate,
	CompanyDelete,
	CompanyUpdate,
	ContactCreate,
	ContactDelete,
	ContactUpdate,
	EmailCreate,
	EmailUndo,
	IssueLink,
	IssueLinkExisting,
	IssuesUpsert,
	IssueUnlink,
	IssueUpdate,
	NoteTemplateUpdate,
	ProjectCreate,
	ProjectUpdate,
	RecordingDelete,
	RecordingUpdate,
	SegmentCreate,
	SegmentDelete,
	SegmentUpdate,
	SlackMessageCreate,
	SlackMessageUndo,
	SnippetCreate,
	SnippetDelete,
	SnippetUpdate,
	TagCreate,
	TagDelete,
	TagGroupCreate,
	TagGroupDelete,
	TagGroupUpdate,
	TagUpdate,
	ThreadCreate,
	ThreadDelete,
	ThreadDeleteBulk,
	ThreadUpdate,
	ThreadUpdateBulk,
	UtteranceDelete,
	UtteranceUpdate,
	VideoDelete,
	VideoUpdate,
	WidgetLinksUpdate,
	WidgetSettingsUpdate,
	WorkspaceUpdate,
} from "@productlane/api"

import {
	changelogCreate,
	changelogDelete,
	changelogUpdate,
	commentCreate,
	commentDelete,
	commentUpdate,
	companyCreate,
	companyDelete,
	companyUpdate,
	contactCreate,
	contactDelete,
	contactUpdate,
	emailCreate,
	emailUndo,
	issueExistingLink,
	issueLink,
	issuesUpsert,
	issueUnlink,
	issueUpdate,
	noteTemplateUpdate,
	projectCreate,
	projectUpdate,
	recordingDelete,
	recordingUpdate,
	segmentCreate,
	segmentDelete,
	segmentUpdate,
	snippetCreate,
	snippetDelete,
	snippetUpdate,
	tagCreate,
	tagDelete,
	tagGroupCreate,
	tagGroupDelete,
	tagGroupUpdate,
	tagUpdate,
	threadCreate,
	threadDeleteBulk,
	threadsDelete,
	threadUpdate,
	threadUpdateBulk,
	utteranceDelete,
	utteranceUpdate,
	videoDelete,
	videoUpdate,
	widgetLinksUpdate,
	widgetSettingsUpdate,
	workspaceUpdate,
} from "./procedures"
import {
	slackMessageCreate,
	slackMessageUndo,
} from "./procedures/slack-messages"

export function getReplicacheMutators(workspaceId: string) {
	return {
		"changelog-create": (tx: WriteTransaction, args: ChangelogCreate) =>
			changelogCreate({ tx, workspaceId, args }),
		"changelog-update": (tx: WriteTransaction, args: ChangelogUpdate) =>
			changelogUpdate({ tx, workspaceId, args }),
		"changelog-delete": (tx: WriteTransaction, args: ChangelogDelete) =>
			changelogDelete({ tx, workspaceId, args }),

		"comment-create": (tx: WriteTransaction, args: CommentCreate) =>
			commentCreate({ tx, workspaceId, args }),
		"comment-update": (tx: WriteTransaction, args: CommentUpdate) =>
			commentUpdate({ tx, workspaceId, args }),
		"comment-delete": (tx: WriteTransaction, args: CommentDelete) =>
			commentDelete({ tx, workspaceId, args }),

		"company-create": (tx: WriteTransaction, args: CompanyCreate) =>
			companyCreate({ tx, workspaceId, args }),
		"company-update": (tx: WriteTransaction, args: CompanyUpdate) =>
			companyUpdate({ tx, workspaceId, args }),
		"company-delete": (tx: WriteTransaction, args: CompanyDelete) =>
			companyDelete({ tx, workspaceId, args }),

		"contact-create": (tx: WriteTransaction, args: ContactCreate) =>
			contactCreate({ tx, workspaceId, args }),
		"contact-update": (tx: WriteTransaction, args: ContactUpdate) =>
			contactUpdate({ tx, workspaceId, args }),
		"contact-delete": (tx: WriteTransaction, args: ContactDelete) =>
			contactDelete({ tx, workspaceId, args }),

		"email-create": (tx: WriteTransaction, args: EmailCreate) =>
			emailCreate({ tx, workspaceId, args }),

		"email-undo": (tx: WriteTransaction, args: EmailUndo) =>
			emailUndo({ tx, workspaceId, args }),

		"issue-link-existing": (tx: WriteTransaction, args: IssueLinkExisting) =>
			issueExistingLink({ tx, workspaceId, args }),

		"issue-link": (tx: WriteTransaction, args: IssueLink) =>
			issueLink({ tx, workspaceId, args }),
		"issue-unlink": (tx: WriteTransaction, args: IssueUnlink) =>
			issueUnlink({ tx, workspaceId, args }),
		"issue-update": (tx: WriteTransaction, args: IssueUpdate) =>
			issueUpdate({ tx, workspaceId, args }),
		"issues-upsert": (tx: WriteTransaction, args: IssuesUpsert) =>
			issuesUpsert({ tx, workspaceId, args }),

		"note-template-update": (tx: WriteTransaction, args: NoteTemplateUpdate) =>
			noteTemplateUpdate({ tx, workspaceId, args }),
		"widget-settings-update": (
			tx: WriteTransaction,
			args: WidgetSettingsUpdate,
		) => widgetSettingsUpdate({ tx, workspaceId, args }),
		"widget-link-update": (tx: WriteTransaction, args: WidgetLinksUpdate) =>
			widgetLinksUpdate({ tx, workspaceId, args }),

		"project-create": (tx: WriteTransaction, args: ProjectCreate) =>
			projectCreate({ tx, workspaceId, args }),
		"project-update": (tx: WriteTransaction, args: ProjectUpdate) =>
			projectUpdate({ tx, workspaceId, args }),

		"recording-delete": (tx: WriteTransaction, args: RecordingDelete) =>
			recordingDelete({ tx, workspaceId, args }),
		"recording-update": (tx: WriteTransaction, args: RecordingUpdate) =>
			recordingUpdate({ tx, workspaceId, args }),

		"segment-create": (tx: WriteTransaction, args: SegmentCreate) =>
			segmentCreate({ tx, workspaceId, args }),
		"segment-update": (tx: WriteTransaction, args: SegmentUpdate) =>
			segmentUpdate({ tx, workspaceId, args }),
		"segment-delete": (tx: WriteTransaction, args: SegmentDelete) =>
			segmentDelete({ tx, workspaceId, args }),

		"tag-create": (tx: WriteTransaction, args: TagCreate) =>
			tagCreate({ tx, workspaceId, args }),
		"tag-update": (tx: WriteTransaction, args: TagUpdate) =>
			tagUpdate({ tx, workspaceId, args }),
		"tag-delete": (tx: WriteTransaction, args: TagDelete) =>
			tagDelete({ tx, workspaceId, args }),

		"tag-group-create": (tx: WriteTransaction, args: TagGroupCreate) =>
			tagGroupCreate({ tx, workspaceId, args }),
		"tag-group-update": (tx: WriteTransaction, args: TagGroupUpdate) =>
			tagGroupUpdate({ tx, workspaceId, args }),
		"tag-group-delete": (tx: WriteTransaction, args: TagGroupDelete) =>
			tagGroupDelete({ tx, workspaceId, args }),

		"thread-create": (tx: WriteTransaction, args: ThreadCreate) =>
			threadCreate({ tx, workspaceId, args }),
		"thread-update": (tx: WriteTransaction, args: ThreadUpdate) =>
			threadUpdate({ tx, workspaceId, args }),
		"thread-delete": (tx: WriteTransaction, args: ThreadDelete) =>
			threadsDelete({ tx, workspaceId, args }),
		"thread-update-bulk": (tx: WriteTransaction, args: ThreadUpdateBulk) =>
			threadUpdateBulk({ tx, workspaceId, args }),
		"thread-delete-bulk": (tx: WriteTransaction, args: ThreadDeleteBulk) =>
			threadDeleteBulk({ tx, workspaceId, args }),

		"utterance-update": (tx: WriteTransaction, args: UtteranceUpdate) =>
			utteranceUpdate({ tx, workspaceId, args }),
		"utterance-delete": (tx: WriteTransaction, args: UtteranceDelete) =>
			utteranceDelete({ tx, workspaceId, args }),

		"video-update": (tx: WriteTransaction, args: VideoUpdate) =>
			videoUpdate({ tx, workspaceId, args }),
		"video-delete": (tx: WriteTransaction, args: VideoDelete) =>
			videoDelete({ tx, workspaceId, args }),

		"slack-message-create": (tx: WriteTransaction, args: SlackMessageCreate) =>
			slackMessageCreate({ tx, workspaceId, args }),
		"slack-message-undo": (tx: WriteTransaction, args: SlackMessageUndo) =>
			slackMessageUndo({ tx, workspaceId, args }),

		"snippet-create": (tx: WriteTransaction, args: SnippetCreate) =>
			snippetCreate({ tx, workspaceId, args }),
		"snippet-delete": (tx: WriteTransaction, args: SnippetDelete) =>
			snippetDelete({ tx, workspaceId, args }),
		"snippet-update": (tx: WriteTransaction, args: SnippetUpdate) =>
			snippetUpdate({ tx, workspaceId, args }),

		"workspace-update": (tx: WriteTransaction, args: WorkspaceUpdate) =>
			workspaceUpdate({ tx, workspaceId, args }),
	}
}
