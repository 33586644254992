import type { VariantProps } from "class-variance-authority"
import { cva } from "class-variance-authority"

export const typographyVariants = cva(
	"prose dark:prose-invert prose-a:break-words",
	{
		variants: {
			variant: {
				editor:
					"prose-h1:text-[1.5rem] prose-h1:font-bold prose-h2:text-[1.25rem] prose-h3:text-[1.125rem] prose-h4:text-[1rem] prose-h5:text-[1rem] prose-h6:text-[1rem] max-w-none",
				blog: "prose-h2:mt-12 prose-h2:tracking-tight prose-h3:tracking-tight prose-h2:mb-4 prose-h2:font-semibold prose-h3:pt-2 prose-h3:text-[1.25rem] prose-p:pb-1 prose-p:text-[1rem] prose-p:font-normal prose-a:cursor-pointer prose-a:text-green-600 prose-a:no-underline dark:prose-a:text-green-500",
				changelog: "",
			},
		},
	},
)

export type typographyVariantsType = VariantProps<
	typeof typographyVariants
>["variant"]
