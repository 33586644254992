import type { Config } from "tailwindcss"

import baseConfig from "@productlane/tailwind-config"

export default {
	corePlugins: {
		// we'll copy the relevant parts to not interfere with radix
		preflight: false,
	},
	content: [
		"index.html",
		"./src/**/*.{ts,tsx}",
		"../../packages/ui/src/**/*.{ts,tsx}",
	],
	presets: [baseConfig],
	theme: {
		extend: {
			fontFamily: {
				sans: ["var(--font-inter)"],
			},
			fontSize: {
				xxs: "0.6875rem",
				xs: "0.8125rem",
				sm: "0.8425rem",
			},
		},
	},
} satisfies Config
