/* eslint-disable prefer-const */
import type { Config } from "tailwindcss"
import typography from "@tailwindcss/typography"
import tailwindAnimate from "tailwindcss-animate"
import tailwindColors from "tailwindcss/colors"
import { fontFamily } from "tailwindcss/defaultTheme"
// @ts-expect-error ts stuff
import flattenColorPalette from "tailwindcss/lib/util/flattenColorPalette"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function addVariablesForColors({ addBase, theme }: any) {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
	let allColors = flattenColorPalette(theme("colors"))
	let newVars = Object.fromEntries(
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		Object.entries(allColors).map(([key, val]) => [`--${key}`, val]),
	)

	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	addBase({
		":root": newVars,
	})
}

export default {
	content: [""],
	theme: {
		fontSize: {
			xxs: ["0.6875rem", "1.5"],
			xs: ["0.75rem", "1.5"],
			sm: ["0.8125rem", "1.5"],
			base: ["0.9375rem", "1.5"],
			lg: ["1rem", "1.5"],
			xl: ["1.125rem", "1.3"],
			"2xl": ["1.25rem", "1.3"],
			"3xl": ["1.5rem", "1.3"],
			"4xl": ["2.441rem", "1.3"],
			"5xl": ["3.052rem", "1.3"],
			"6xl": ["4rem", "1.3"],
		},
		colors: {
			current: tailwindColors.current,
			transparent: tailwindColors.transparent,
			black: tailwindColors.black,
			white: tailwindColors.white,
			brand: tailwindColors.neutral,
			plane: "var(--text-plane)",
			gray: {
				...tailwindColors.neutral,
				450: "#8C8C8C",
				850: "#1F1F1F",
			},
			green: {
				50: "#eefdfc",
				100: "#d3fafa",
				200: "#acf2f5",
				300: "#73e8ed",
				400: "#33d3dd",
				500: "#18bdca",
				600: "#1692a4",
				700: "#197685",
				800: "#1d606d",
				900: "#1c505d",
				950: "#0d353f",
			},
			red: tailwindColors.red,
			amber: tailwindColors.amber,
			yellow: tailwindColors.yellow,
			purple: {
				...tailwindColors.purple,
			},
			blue: tailwindColors.blue,
			inherit: tailwindColors.inherit,
		},
		extend: {
			boxShadow: {
				button: "0px 1px 1px rgba(0, 0, 0, 0.06)",
				// pl: "0px 4px 18px rgba(0, 0, 0, 0.1)",
				// tags: "0px 2px 5px rgba(0, 0, 0, 0.06)",
				wider: "0px 16px 70px rgba(0, 0, 0, 0.07)",
				// widerDark: "0px 16px 70px rgba(0, 0, 0, 0.24)",
				// plFlat: "0 0 20px rgba(0, 0, 0, 0.15)",
				dark: "0px 7px 24px rgba(0, 0, 0, 0.55)",
				// extraDark: "0px 7px 24px rgba(0, 0, 0, 0.90)",
				kbar: "0px 36px 70px rgba(0,0,0,0.26)",
				left: "-40px 0px 300px 20px",
			},
			textColor: {
				primary: "var(--text-primary)",
				secondary: "var(--text-secondary)",
				tertiary: "var(--text-tertiary)",
			},
			stroke: {
				primary: "var(--text-primary)",
				secondary: "var(--text-secondary)",
				tertiary: "var(--text-tertiary)",
				test: "red",
				white: "white",
				strokeWidth: {
					4: "12px",
				},
			},
			fontFamily: {
				sans: ["var(--font-sans)", ...fontFamily.sans],
			},
			gridTemplateColumns: {
				14: "repeat(14, minmax(0, 1fr))",
			},
			backgroundImage: {
				"gradient-radial": "radial-gradient(var(--tw-gradient-stops))",
			},
			maxWidth: {
				xxs: "288px",
			},
			zIndex: {
				1: "1",
			},
			gap: {
				inherit: "inherit",
			},
			transitionDelay: {
				400: "400ms",
				600: "600ms",
				800: "800ms",
			},
			animation: {
				"shape-stagger": "shape-stagger 0.8s ease-out",
				"timeline-box": "timeline-box 5s linear",
				"fade-in-text":
					"fade-in-text 1000ms ease 0s 1 normal backwards running",
				"arrow-fade-in": "arrow-fade-in 1s ease-out",
				"accordion-down": "accordion-down 0.2s ease-out",
				"accordion-up": "accordion-up 0.15s ease-out",
				"collapsible-down": "collapsible-down 0.2s ease-out",
				"collapsible-up": "collapsible-up 0.15s ease-out",
				"fade-in": "fade-in 0.2s ease-out",
			},
			transitionTimingFunction: {
				// curves by Benjamin De Cock: https://www.notion.so/productlane/Animations-908a29c221f14038b47e9d270a18ff85
				// using quad as the default, ordered from slow to fast
				out: "cubic-bezier(.25, .46, .45, .94)",
				"out-quad": "cubic-bezier(.25, .46, .45, .94)",
				"out-cubic": "cubic-bezier(.215, .61, .355, 1)",
				"out-quart": "cubic-bezier(.165, .84, .44, 1)",
				"out-quint": "cubic-bezier(.23, 1, .32, 1)",
				"out-expo": "cubic-bezier(.19, 1, .22, 1)",
				"out-circ": "cubic-bezier(.075, .82, .165, 1)",

				"in-expo": "cubic-bezier(0.95, 0.05, 0.795, 0.035)",

				// using quad as the default, ordered from slow to fast
				"in-out": "cubic-bezier(.455, .03, .515, .955)",
				"in-out-quad": "cubic-bezier(.455, .03, .515, .955)",
				"in-out-cubic": "cubic-bezier(.645, .045, .355, 1)",
				"in-out-quart": "cubic-bezier(.77, 0, .175, 1)",
				"in-out-quint": "cubic-bezier(.86, 0, .07, 1)",
				"in-out-expo": "cubic-bezier(1, 0, 0, 1)",
				"in-out-circ": "cubic-bezier(.785, .135, .15, .86)",
			},
			keyframes: {
				"fade-in": {
					"0%": { opacity: "0" },
					"100%": { opacity: "1" },
				},
				"arrow-fade-in": {
					"0%": { opacity: "0", transform: "scale(90%)" },
					"100%": { opacity: "1", transform: "scale(100%)" },
				},
				"shape-stagger": {
					"0%": { transform: "scaleY(0%)", fillOpacity: "0" },
					"100%": { transform: "scaleY(100%)", fillOpacity: "1" },
				},
				"timeline-box": {
					"0%": { opacity: "0", transform: "translateY(0rem)" },
					"16.6%": { opacity: "1", transform: "translateY(24rem)" },
					"33.2%": { opacity: "0", transform: "translateY(48rem)" },
					"49.8%": { opacity: "1", transform: "translateY(72rem)" },
					"66.4%": { opacity: "0", transform: "translateY(96rem)" },
					"85.4%": { opacity: "1", transform: "translateY(120rem)" },
					"100%": { opacity: "0", transform: "translateY(145rem)" },
				},
				"fade-in-text": {
					"0%": { opacity: "0", transform: "translateY(-10px)" },
					"100%": { opacity: "1", transform: "translateY(0px)" },
				},
				"accordion-down": {
					from: { height: "0" },
					to: { height: "var(--radix-accordion-content-height)" },
				},
				"accordion-up": {
					from: { height: "var(--radix-accordion-content-height)" },
					to: { height: "0" },
				},
				"collapsible-up": {
					from: { height: "var(--radix-collapsible-content-height)" },
					to: { height: "0" },
				},
				"collapsible-down": {
					from: { height: "0" },
					to: { height: "var(--radix-collapsible-content-height)" },
				},
			},
		},
	},
	plugins: [addVariablesForColors, tailwindAnimate, typography],
} satisfies Config
